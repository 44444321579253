import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/angular-developers/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-typescript/Empower"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/Solutions"
import Success from "../components/web-application-development-in-usa/Success"
import WhyHireAngular from "../components/angular-developers/WhyHireAngular"
import Maximize from "../components/angular-developers/Maximize"
import HowHireAngular from "../components/angular-developers/HowHireAngular"
import HireDevelopers from "../components/angular-developers/HireDevelopers"
import AngularTechStacks from "../components/angular-developers/AngularTechStacks"
import AngularTechStacksMobile from "../components/angular-developers/AngularTechStacksMobile"
import AngularCombinitation from "../components/angular-developers/AngularCombination"
import AngularIndustries from "../components/angular-developers/AngularIndustries"
import QuickReads from "../components/angular-developers/QuickReads"
import Remote from "../components/angular-developers/Remote"
import Finest from "../components/angular-developers/Finest"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta name="googlebot" content="noindex, follow"></meta>
    </>
  )
}
const hireAngular = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const maximizeAngular = data?.strapiPage?.sections[2]
  const how = data?.strapiPage?.sections[3]
  const remote = data?.strapiPage.sections[4]
  const developers = data?.strapiPage?.sections[5]
  const TechStacksAngular = data?.strapiPage?.sections[6]
  const angularcombinitation = data?.strapiPage?.sections[7]
  const essential = data?.strapiPage?.sections[8]
  const industriesAngular = data?.strapiPage?.sections[9]
  const testimonials = data?.strapiPage?.sections[11]
  const quick = data?.strapiPage?.sections[12]
  // const testimonials = data?.strapiPage?.sections[13]
  const faqs = data?.strapiPage?.sections[14]
  const cta = data?.strapiPage?.sections[15]
  const port = data?.strapiPage?.sections[16]
  // const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
      />
      <Statics />
      <WhyHireAngular strapiData={whyhire} />
      <Maximize strapiData={maximizeAngular} angularMaximize={true} />
      <HireDevelopers strapiData={developers} />
      <HowHireAngular strapiData={how} />
      <Empower strapiData={essential} />
      <Remote strapiData={remote} />
      <AngularCombinitation strapiData={angularcombinitation} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Finest strapiData={cta} />
      <AngularIndustries strapiData={industriesAngular} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={port} />
      ) : (
        <SolutionsMobile strapiData={port} />
      )}
      <Success strapiData={testimonials} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "hire-angular-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default hireAngular
